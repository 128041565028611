import { Container } from '@/components/styled/Container'
import { DeviceType, useDeviceType } from '@/hooks/useDeviceType'
import { media } from '@/styles/media'
import { motion, useScroll, useTransform } from 'framer-motion'
import React, { useEffect, useRef, useState } from 'react'

import Image1 from '@/assets/images/about/gallery/01.jpg'
import Image2 from '@/assets/images/about/gallery/02.jpg'
import Image3 from '@/assets/images/about/gallery/03.jpg'
import Image4 from '@/assets/images/about/gallery/04.jpg'
import Image5 from '@/assets/images/about/gallery/05.jpg'
import Image6 from '@/assets/images/about/gallery/06.jpg'
import Image7 from '@/assets/images/about/gallery/07.jpg'
import Image8 from '@/assets/images/about/gallery/08.jpg'
import styled from '@emotion/styled'

const Wrapper = styled(motion.div)`
  overflow-x: auto;
  overflow-y: hidden;
  z-index: 2;
  -ms-overflow-style: none;
  scrollbar-width: none;

  &::-webkit-scrollbar {
    display: none;
  }
`

const Montage = styled(motion.div)`
  padding: 60px 0 0 0;

  width: 100%;
  max-width: 100%;
  min-height: fit-content;
  max-height: fit-content;

  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  flex: 1;
  gap: 24px;

  ${media.tabletLandscapeDown} {
    padding: 0;
    gap: 16px;
  }
`

const Group = styled.div`
  display: flex;

  flex-direction: column;
  align-items: flex-end;
  gap: 24px;

  ${media.tabletLandscapeDown} {
    gap: 14.4px;
  }
`

const Image = styled.img`
  border-radius: 12px;
  object-fit: cover;

  &.square-image {
    width: 360px;
    height: 298px;
  }
  &.portrait-image {
    width: 600px;
    height: 620px;
  }

  ${media.tabletLandscapeDown} {
    &.square-image {
      width: 291.6px;
      height: 193.2px;
    }
    &.portrait-image {
      width: 265.2px;
      height: 400.8px;
    }
  }
`

export default function HowWeWork() {
  const { deviceType, width: deviceWidth } = useDeviceType()
  const [translateX, setTranslateX] = useState(['', ''])

  useEffect(() => {
    let start = ''
    let end = ''

    if (deviceType === DeviceType.Mobile) {
      end = (5.5).toFixed(2)
      start = (-0.8125 * deviceWidth + 596.375).toFixed(2)

      setTranslateX([`-${start}%`, `${end}%`])
    } else {
      end = (-0.0024375 * deviceWidth + 10.18).toFixed(2)
      start = (-0.089583 * deviceWidth + 200.99936).toFixed(2)

      setTranslateX([`-${start}%`, `${end}%`])
    }
  }, [deviceWidth])

  const ref = useRef(null)

  const { scrollYProgress } = useScroll({
    target: ref
  })

  const range = deviceType === DeviceType.Mobile ? [0.5, 0.8] : [0, 1]

  const x = useTransform(scrollYProgress, range, translateX)

  return (
    <Container
      style={{
        zIndex: 2,
        background: '#002E4E',
        backgroundBlendMode: 'overlay, multiply, normal'
      }}
    >
      <Wrapper ref={ref}>
        <Montage style={{ x }}>
          <Group>
            <Image src={Image1} className="square-image" />
            <Image src={Image2} className="square-image" />
          </Group>
          <Image src={Image3} className="portrait-image" />
          <Group>
            <Image src={Image4} className="square-image" />
            <Image src={Image5} className="square-image" />
          </Group>
          <Group>
            <Image src={Image6} className="square-image" />
            <Image src={Image7} className="square-image" />
          </Group>
          <Group>
            <Image src={Image8} className="portrait-image" />
          </Group>
        </Montage>
      </Wrapper>
    </Container>
  )
}
